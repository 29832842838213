import { createSlice, PayloadAction, nanoid, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import fetchStatus from "../types/fetchStatus";

import ApiClient from "../Helpers/ApiClient";


interface CustomersState {
    customers: {
       id: string
       name: string
    }[]
    lastUsed: string | null
    status: fetchStatus
    error: string | null
}

const initialState: CustomersState = {
    customers: [],
    lastUsed: null,
    status: fetchStatus.idle,
    error: null
}

export const fetchCustomers = createAsyncThunk('fetchCustomers', async (args, { getState }) => {
    const state = getState() as RootState;
    if (!state.user.token) throw new Error('API called with no token');
    // return await ApiClient.makeSignedRequest(state.user.token, 'api/customerMappings');
    return await ApiClient.makeSignedRequest(state.user.token, 'api/customerMappings');
})

export const customersSlice = createSlice({
    name: 'customers',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setLastUsed: (state, action: PayloadAction<string>) => {
            state.lastUsed = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCustomers.pending, (state, action) => {
                state.status = fetchStatus.loading
            })
            .addCase(fetchCustomers.fulfilled, (state, action) => {
                state.status = fetchStatus.succeeded
                state.customers = action.payload.customers
            })
            .addCase(fetchCustomers.rejected, (state, action) => {
                state.status = fetchStatus.failed
                state.error = 'Failed to fetch customers'
            })
    }
})
export const { setLastUsed} = customersSlice.actions

export const getAllCustomers = (state: RootState) => state.allCustomers.customers
export const getLastUsedCustomer = (state: RootState) => state.allCustomers.lastUsed
export default customersSlice.reducer
